import React from 'react';

import Main from '../../components/home/main';
import Sponsors from '../../components/home/sponsors';
import './home.scss';

const Home = () => {
  return (
    <div>
      <Main />
      <Sponsors />
    </div>
  );
};

export default Home;
