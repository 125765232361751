import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Home from './pages/home/Home';

export default class Routes extends Component {
  renderRoutes = () => (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );

  render() {
    return <Fragment>{this.renderRoutes()}</Fragment>;
  }
}
