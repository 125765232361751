import React from 'react';

import './main.scss';
import Cta from '../../../assets/tech-cxo-button.svg';
import Community from '../../../assets/tech-cxo-community.png';

const Main = () => {
  return (
    <div className="main">
      <div className="main__background" />
      <div className="main__background--gif" />
      <div className="main__content">
        <div className="main__content__btn">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScSUG7QR4F7Tvkd2NDtXvCs7d6b5u9JdYtdv8mTsxSr68-45g/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Cta} alt="Join Us" />
            <span>Join Us</span>
          </a>
        </div>
      </div>
      <div className="main__background--community">
        <img src={Community} alt="Tech CXO" />
      </div>
    </div>
  );
};

export default Main;
