import React from 'react';

import Logos from '../../../assets/tech-cxo-sponsors.png';
import './sponsors.scss';

const Sponsors = () => {
  return (
    <div className="sponsors">
      <span>Contamos con el apoyo de</span>
      <div className="sponsors__images-container">
        <img src={Logos} alt="Sponsors" />
      </div>
      <a href="mailto:hola@cxos.com.ar">hola@cxos.com.ar</a>
    </div>
  );
};

export default Sponsors;
